<template>
    <!-- <a-modal
      :visible="visible"
      title='新增型号'
      okText='提交'
      @cancel="() => { $emit('cancel') }"
      @ok="() => { $emit('create') }"
    > -->
    <popup-form
      :visible="visible"
      :title='title'
      :maskClosable="false"
      @cancel="() => { $emit('cancel') }"
      @ok="() => { $emit('create') }"
    >
      <a-form layout='vertical' :form="form" @submit='submitForm'>
        <a-form-item label='设备名称'>
          <a-input
            v-decorator="[
              'newmodel.deviceModelName',
              {
                initialValue: formdata.deviceModelName,
                rules: [{ required: true, message: '请输入设备名称' }],
              }
            ]"
          />
        </a-form-item>
        <a-form-item label='设备型号'>
          <a-input
            v-decorator="[
              'newmodel.deviceModelType',
              {
                initialValue: formdata.deviceModelType,
                rules: [{ required: true, message: '请输入设备型号' }],
              }
            ]"
          />
        </a-form-item>
        <a-form-item label='备注'>
          <a-input
            type='textarea'
            v-decorator="[
              'newmodel.remarks',
              {
                initialValue: formdata.remarks,
              }
            ]"
          />
        </a-form-item>
        <a-form-item label='设备处理功能'>
          <a-checkbox-group
            v-decorator="[
              'newmodel.clwgn', 
              { initialValue: formdata.clwgn, 
                rules: [{ required: true, message: '请选择一种' }], 
              }
            ]"
            style="width: 100%;"
          >
            <a-checkbox value="yjgf">
              有机固废
            </a-checkbox>
            <a-checkbox value="yllj">
              园林垃圾
            </a-checkbox>
          </a-checkbox-group>
        </a-form-item>
        <a-button style="display: none;" type="primary" html-type="submit">
          Submit
        </a-button>
      </a-form>
    </popup-form>
    <!-- </a-modal> -->
</template>

<script>
import PopupForm from '../../components/PopupForm.vue';
export default {
  components: { PopupForm },
  name: 'subForm',
  props: {
    title: {
      type: String,
      default: '新增型号'
    },
    'visible': {
      type: Boolean,
      default: false
    },
    formdata: {
      type: Object,
      default: () => {
        return {
          deviceModelName: '',
          deviceModelType: '',
          remarks: '',
          clwgn: [],
        }
      }
    }
  },
  data() {
    return {
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this);
  },
  methods: {
    submitForm(e) {
      console.log('submitForm', e)
    },
  }
}
</script>
<style lang="less" scoped>
.hide {
  display: none;
}
</style>