<template>
  <popup-form
    title="故障编码"
    :maskCloseAble="false"
    :visible="visible"
    :maskClosable="false"
    :width="1200"
    :footer="null"
    @cancel="onClose"
  >
    <div class="faultCodeList">
      <div class="operator">
        <a-space>
          <a-input-search
            class="operator2_search"
            v-model="keyword"
            placeholder="搜索故障编号或描述"
            allow-clear
            :disabled="tableLoading"
            @search="onSearch"
          >
            <a-button
              type="primary"
              icon="search"
              slot="enterButton"
              :disabled="tableLoading"
            >
              搜索
            </a-button>
          </a-input-search>
        </a-space>
        <a-space>
          <a-button @click="AddFault" type="primary" :disabled="tableLoading"
            ><a-icon type="plus" />新增</a-button
          >
          <template>
            <!-- <template v-if="!noneSelected"> -->
            <!-- <a-button @click="saveAll" type="primary"
              ><a-icon type="save" />批量保存</a-button
            >
            <a-button @click="delectSelected" type="primary"
              ><a-icon type="save" />批量删除</a-button
            > -->
            <a-dropdown>
              <a-menu slot="overlay" @click="handleActoinClick">
                <a-menu-item key="1">
                  <a-icon type="save" />批量保存
                </a-menu-item>
                <a-menu-item key="2">
                  <a-icon type="delete" />批量删除
                </a-menu-item>
                <a-menu-item key="3">
                  <a-icon type="undo" />取消选择
                </a-menu-item>
              </a-menu>
              <a-button :loading="actionLoading" type="primary">
                批量操作 <a-icon type="down" />
              </a-button>
            </a-dropdown>
          </template>
          <a-upload
            name="file"
            accept=".xlsx, .csv"
            :disabled="tableLoading"
            :customRequest="uploadfile"
            :showUploadList="false"
          >
            <a-button type="primary" :disabled="tableLoading"
              ><a-icon type="upload" />导入数据信息</a-button
            >
          </a-upload>
          <a-button @click="exportTemp" type="primary" :disabled="tableLoading"
            ><a-icon type="download" />下载为模板</a-button
          >
          <!-- <a-button @click="exportTest" type="primary">测试</a-button> -->
        </a-space>
      </div>
      <form :autoFormCreate="(form) => (this.form = form)">
        <a-table
          class="code_table"
          :columns="dataColumns"
          :dataSource="selectedRows"
          :pagination="pagination"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :loading="tableLoading"
          :scroll="tableScroll"
          @change="onTableChange"
        >
          <template slot="errCode" slot-scope="text, record">
            <a-input
              :key="record.ct"
              v-if="record.editable && record.isNew"
              style="margin: -6px 0; text-align: center;"
              :ref="'errCode' + record.key"
              :value="text"
              placeholder="故障代码"
              @change="
                (e) => handleChange(e.target.value, record.key, 'errCode')
              "
            >
              <a-icon slot="prefix" type="form" />
              <template v-if="record.isTErr" slot="suffix">
                <a-tooltip title="故障代码不能为空">
                  <a-icon type="info-circle" style="color: red" />
                </a-tooltip>
              </template>
            </a-input>
            <span v-else-if="searchText">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <template slot="errContent" slot-scope="text, record">
            <a-input
              :key="record.ct"
              v-if="record.editable"
              style="margin: -6px 0; text-align: center;"
              :ref="'errContent' + record.key"
              :value="text"
              placeholder="故障描述"
              @change="
                (e) => handleChange(e.target.value, record.key, 'errContent')
              "
            >
              <a-icon slot="prefix" type="form" />
              <template v-if="record.isErr" slot="suffix">
                <a-tooltip :title="record.err">
                  <a-icon type="info-circle" style="color: red" />
                </a-tooltip>
              </template>
            </a-input>
            <span v-else-if="searchText">
              <template
                v-for="(fragment, i) in text
                  .toString()
                  .split(
                    new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                  )"
              >
                <mark
                  v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                  :key="i"
                  class="highlight"
                  >{{ fragment }}</mark
                >
                <template v-else>{{ fragment }}</template>
              </template>
            </span>
            <template v-else>{{ text }}</template>
          </template>
          <!-- <template
            v-for="(col, i) in ['errContent']"
            :slot="col"
            slot-scope="text, record"
          >
            <a-input
              :key="col"
              v-if="record.editable"
              style="margin: -6px 0; text-align: center;"
              :ref="'' + col + record.key"
              :value="text"
              :placeholder="columns[i].title"
              @change="(e) => handleChange(e.target.value, record.key, col)"
            />
            <template v-else>{{ text }}</template>
          </template> -->
          <template slot="operation" slot-scope="text, record">
            <template v-if="record.editable">
              <span v-if="record.isNew">
                <a @click="saveRow(record.key)">保存</a>
                <a-divider type="vertical" />
                <a-popconfirm
                  title="是否要删除此行?"
                  @confirm="removeRow(record)"
                >
                  <a>删除</a>
                </a-popconfirm>
              </span>
              <span v-else>
                <a @click="saveRow(record.key)">保存</a>
                <a-divider type="vertical" />
                <a class="warn_color" @click="cancleEdit(record.key)">取消</a>
              </span>
            </template>
            <span v-else>
              <a @click="editRow(record.key)">编辑</a>
              <a-divider type="vertical" />
              <a-popconfirm
                title="是否要删除此行?"
                @confirm="removeRow(record)"
              >
                <a>删除</a>
              </a-popconfirm>
            </span>
          </template>
        </a-table>
      </form>
    </div>
    <popup-form
      :visible="addNewForm"
      :width="600"
      :maskClosable="false"
      :centered="true"
      :title="confirmTitle"
      oktext="确定"
      :destroyOnClose="true"
      @cancel="
        () => {
          this.addNewForm = false;
          this.confirmDialge = undefined;
        }
      "
      @ok="
        () => {
          this.addNewForm = false;
          if (this.confirmDialge) {
            this.confirmDialge();
          }
        }
      "
    >
      <div style="height: 100px;padding:30px;font-size:large;">
        {{ confirmText }}
      </div>
    </popup-form>
  </popup-form>
</template>

<script>
import PopupForm from "../../components/PopupForm.vue";
import { dataSource as ds } from "@/services";
import moment from "moment";
import * as XLSX from "xlsx/xlsx.mjs";
// import { format } from "date-fns";
const columns = [
  // {
  //   title: "id",
  //   dataIndex: "errCodeId",
  //   key: "errCodeId",
  //   width: "20%",
  //   scopedSlots: { customRender: "liaisonName" },
  // },
  {
    title: "故障代码",
    dataIndex: "errCode",
    key: "errCode",
    width: "20%",
    scopedSlots: { customRender: "errCode" },
  },
  {
    title: "故障描述",
    dataIndex: "errContent",
    key: "errContent",
    // width: "40%",
    scopedSlots: { customRender: "errContent" },
  },
  {
    title: "操作",
    key: "operation",
    width: "20%",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  name: "FaultCodeList",
  components: { PopupForm },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      columns,
      addNewForm: false,
      keyword: "",
      searchText: "",
      advanced: true,
      showForm: false,
      dataSource: [],
      selectedRows: [],
      total: 0,
      status: undefined,
      tempObj: null,
      // tableScroll: {
      //     y: 560
      // },
      tableScroll: undefined,
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "50", "100"],
        showSizeChanger: true,
      },
      rowSelection: {},
      selectedRowKeys: [],
      noneSelected: true,
      actionLoading: false,
      tableLoading: false,
      confirmTitle: "",
      confirmText: "",
      confirmDialge: undefined,
    };
  },
  computed: {
    dataColumns() {
      return this.columns;
    },
  },
  // authorize: {
  //   deleteRecord: 'delete'
  // },
  mounted() {
    console.log("FaultCodeList mounted");
    this.update();
  },
  methods: {
    moment,
    update() {
      console.log("FaultCodeList update", this.id);
      this.tableLoading = true;
      ds.getErrCodeList({
        deviceModelId: this.id,
        // deviceModelId: '55fcb342ff944b3eb4097403b82e6518',
      })
        .then((res) => {
          console.log("FaultCodeList res", res.data);
          this.dataSource = res.data.data.data.map((d, i) => {
            return {
              ...d,
              key: i,
              editable: false,
              isNew: false,
              isErr: false,
              ct: "" + Date.now() + i,
            };
          });
          // console.log("FaultCodeList dataSource", this.dataSource);
          this.selectedRows = this.dataSource.map((d) => {
            return { ...d };
          });
          this.total = this.selectedRows.length;
          // this.pagination.current = res.data.data.list.pageNum;
        })
        .catch((err) => {
          this.$message.error(`获取故障编码错误：${err}`);
          console.log("FaultCodeList Err", err);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    async removeRow(record) {
      try {
        let ret = await this.remove(record.key);
        console.log("saveRow ret", ret);
        this.$message.success(ret);
      } catch (e) {
        this.$message.warn(e);
      }
    },
    async remove(key) {
      console.log("remove record", key);
      let target = this.selectedRows.filter((item) => item.key === key)[0];
      if (target === undefined) return;
      if (target.isNew) {
        let action = () => {
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve();
            }, 5);
          });
        };
        await action();
        target.editable = false;
        target.isNew = false;
        target.isErr = false;
        const newData = this.selectedRows.filter((item) => item.key !== key);
        this.selectedRows = newData;
        const newSelected = this.selectedRowKeys.filter((item) => item !== key);
        this.selectedRowKeys = newSelected;
        this.onSelectChange(this.selectedRowKeys);
        this.onChange(newData);
      } else {
        try {
          let res = await ds.deleteErrCode({
            errCodeId: target.errCodeId,
          });
          console.log("deleteErrCode", res);
          if (res.data.meta.success) {
            // this.$message.success("删除故障代码成功");
            target.editable = false;
            target.isNew = false;
            target.isErr = false;
            const newData = this.selectedRows.filter(
              (item) => item.key !== key
            );
            this.selectedRows = newData;
            const newSelected = this.selectedRowKeys.filter(
              (item) => item !== key
            );
            this.selectedRowKeys = newSelected;
            this.onSelectChange(this.selectedRowKeys);
            this.onChange(newData);
          } else {
            throw res.data.meta.msg;
          }
        } catch (err) {
          console.log("deleteErrCode Err", err);
          target.err = err;
          target.isErr = true;
          throw "删除失败";
        }
      }
      return "删除成功";
      // const newData = this.selectedRows.filter((item) => item.key !== record.key);
      // this.selectedRows = newData;
      // this.onChange(this.selectedRows);
    },
    async saveRow(key) {
      try {
        let ret = await this.saveKey(key);
        console.log("saveRow ret", ret);
        this.$message.success(ret);
      } catch (e) {
        this.$message.warn(e);
      }
    },
    async saveKey(key) {
      let target = this.selectedRows.filter((item) => item.key === key)[0];
      if (target === undefined) return;
      if (target.editable === false) return;
      if (target.errCode.trim() === "") {
        // this.$nextTick(() => {
        //   target.isTErr = true;
        //   this.$refs["errCode" + target.key].focus();
        // });
        target.isTErr = true;
        target.ct = "" + Date.now();
        throw "故障代码不能为空";
      }
      target.isTErr = false;
      if (target.errContent.trim() === "") {
        target.err = "故障描述不能为空";
        target.isErr = true;
        target.ct = Date.now();
        throw "故障描述不能为空";
      }
      if (target.isNew) {
        try {
          let res = await ds.inssertErrCode({
            deviceModelId: this.id,
            errCode: target.errCode,
            errContent: target.errContent,
          });
          console.log("inssertErrCode res", res);
          if (res.data.meta.success) {
            target.errCodeId = res.data.data.id;
            target.editable = false;
            target.isNew = false;
            target.isErr = false;
            this.onChange(target);
            return "新增故障代码成功";
          } else {
            throw res.data.meta.msg;
          }
        } catch (err) {
          console.log("inssertErrCode Err", err);
          target.err = err;
          target.isErr = true;
          throw "插入失败";
        }
      } else {
        try {
          let res = await ds.updateErrCode({
            deviceModelId: this.id,
            errCodeId: target.errCodeId,
            errCode: target.errCode,
            errContent: target.errContent,
          });
          console.log("updateErrCode res", res);
          if (res.data.meta.success) {
            target.editable = false;
            target.isNew = false;
            target.isErr = false;
            this.onChange(target);
            return "编辑成功";
          } else {
            throw res.data.meta.msg;
          }
        } catch (err) {
          console.log("updateErrCode Err", err);
          target.err = err;
          target.isErr = true;
          console.log("updateErrCode res", target);
          throw "编辑失败";
        }
      }
      // target.editable = false;
      // target.isNew = false;
    },
    editRow(key) {
      console.log("editRow key", key);
      // if (this.selectedRows.filter((item) => item.editable === true).length > 0) {
      //   this.$message.warn("请先保存或取消正在编辑的项目", 3);
      //   return;
      // }
      let target = this.selectedRows.filter((item) => item.key === key)[0];
      console.log("editRow", target);
      this.tempObj = { ...target };
      target.editable = true;
      this.$nextTick(() => {
        console.log(this.$refs["errContent" + target.key]);
        this.$refs["errContent" + target.key].focus();
      });
      // target.editable = !target.editable
      // this.onChange(this.selectedRows)
    },
    cancleEdit(key) {
      let target = this.selectedRows.filter((item) => item.key === key)[0];
      let origin = this.dataSource.filter((item) => item.key === key)[0];
      target.errContent = origin.errContent;
      target.errCode = origin.errCode;
      target.isErr = false;
      console.log("cancleEdit", target, origin);
      target.editable = false;
    },
    handleChange(value, key, column) {
      const newData = [...this.selectedRows];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.selectedRows = newData;
      }
    },
    newMember() {
      // if (this.selectedRows.filter((item) => item.editable === true).length > 0) {
      //   this.$message.warn("请先保存正在编辑的联系人", 3);
      //   return;
      // }
      let newMen = {
        key: Date.now(),
        ct: "" + Date.now(),
        errCode: "",
        errContent: "",
        editable: true,
        isNew: true,
      };
      this.selectedRows.push(newMen);
      this.total = this.selectedRows.length;
      console.log(
        this.pagination.current,
        this.total / this.pagination.pageSize
      );
      this.pagination.current = Math.floor(
        this.total / this.pagination.pageSize + 1
      );
      this.$nextTick(() => {
        this.$refs["errCode" + newMen.key].focus();
      });
      // this.onChange(this.selectedRows);
    },
    onChange(item) {
      // this.$message.info('表格状态改变了')
      let oldItem = this.dataSource.findIndex((i) => i.key === item.key);
      // console.log("onChange", item, oldItem);
      if (oldItem >= 0) {
        this.dataSource[oldItem] = { ...item };
      } else {
        this.dataSource.push({ ...item });
      }
    },
    onClose() {
      console.log("onClose");
      let editingElem = this.selectedRows.find(
        (elem) => elem.editable === true
      );
      if (editingElem !== undefined) {
        console.log("onClose11");
        this.confirmTitle = "提示";
        this.confirmText = "还有未保存的编辑项目，确定放弃编辑直接退出吗？";
        this.confirmDialge = () => {
          this.confirmDialge = undefined;
          this.addNewForm = false;
          this.$emit("cancel");
        };
        this.addNewForm = true;
      } else {
        this.$emit("cancel");
      }
    },
    onTableChange(e) {
      console.log("onTableChange", e);
      const { current, pageSize } = e;
      this.pagination.current = current;
      this.pagination.pageSize = pageSize;
      if (pageSize > 10) {
        this.$nextTick(() => {
          this.tableScroll = {
            y: 560,
          };
        });
      } else {
        this.$nextTick(() => {
          this.tableScroll = undefined;
        });
      }
    },
    onSelectChange(e) {
      // console.log("onSelectChange", e);
      this.selectedRowKeys = e;
      // if (this.selectedRowKeys.length === 0) {
      //   this.noneSelected = true;
      // } else {
      //   this.noneSelected = false;
      // }
    },
    onSearch(e) {
      console.log("onSearch", e);
      this.searchText = e;
      this.selectedRows = this.dataSource.filter(
        (item) => item.errCode.includes(e) || item.errContent.includes(e)
      );
    },
    onSearchChange(e) {
      console.log("onSearchChange", e);
      this.status = e;
      // this.update();
    },
    AddFault() {
      // this.$message.info("新增...");
      // this.addNewForm = true;
      // if (this.selectedRows.filter((item) => item.editable === true).length > 0) {
      //   this.$message.warn("请先保存或取消正在编辑的项目", 3);
      //   return;
      // }
      this.newMember();
    },
    importData(data) {
      let succCout = 0;
      let existCout = 0;
      if (Array.isArray(data) && data.length > 0) {
        let key = Date.now();
        data.forEach((elem, index) => {
          if (
            elem["故障代码"] !== undefined &&
            elem["故障描述"] !== undefined
          ) {
            let exist = this.selectedRows.find(
              (e) => e.errCode === elem["故障代码"]
            );
            if (exist) {
              exist.errContent = elem["故障描述"];
              exist.editable = true;
              exist.ct = "" + key + index;
              succCout++;
              existCout++;
            } else {
              let newMen = {
                key: key + index,
                ct: "" + key + index,
                errCode: elem["故障代码"],
                errContent: elem["故障描述"],
                editable: true,
                isNew: true,
              };
              this.selectedRows.push(newMen);
              succCout++;
            }
          }
        });
      }
      if (existCout === 0) {
        return `已导入${succCout}个故障代码，点击保存生效`;
      }
      return `已导入${succCout}个故障代码(${existCout}个已存在)，点击保存生效`;
    },
    uploadfile(file) {
      return new Promise((resolve) => {
        console.log("uploadfile", file);
        this.readFromLocalFile(file.file, this.importExcel);
        resolve();
      });
    },
    readFromLocalFile(file, callback) {
      var reader = new FileReader();
      reader.onload = function(e) {
        var data = e.target.result;
        var workbook = XLSX.read(data, { type: "binary" });
        if (callback) callback(workbook);
      };
      reader.readAsBinaryString(file);
    },
    importExcel(workbook) {
      let key = "importExcel" + Date.now();
      this.$message.loading({ content: "正在导入...", key, duration: 0 });
      console.log("importExcel", workbook);
      let opts = {
        // header: 1
      };
      var sheetNames = workbook.SheetNames; // 工作表名称集合
      var worksheet = workbook.Sheets[sheetNames[0]]; // 这里我们只读取第一张sheet
      let json = XLSX.utils.sheet_to_json(worksheet, { ...opts });
      console.log("importExcel json", json);
      let ret = this.importData(json);
      this.$message.info({ content: ret, key, duration: 4 });
      // this.readFromLocalFile("file.raw", (e) => {
      //   console.log("importExcel", e);
      // });
    },
    exportTemp() {
      this.$message.info("下载模板...");
      // ds.exportErrCodeExcel().then((res) => {
      //   console.log("exportErrCodeExcel res", res);

      //   const fileName = res.headers["content-disposition"]
      //     .split(";")[1]
      //     .split("=")[1]
      //     .split(".xlsx")[0]; // 根据接口返回情况拿到文件名
      //   const blob = new Blob([res.data], {
      //     type: res.headers["content-type"],
      //   }); // 通过返回的流数据 手动构建blob 流
      //   // const blob = new Blob([res.data], {type: "application/vnd.ms-excel;charset=utf-8"});
      //   const reader = new FileReader();
      //   let url = window.URL.createObjectURL(blob); // 创建新的url并指向file对象或blob对象的地址
      //   reader.readAsDataURL(blob); // 转换为base64，可以直接放入a标签的href
      //   reader.onload = (e) => {
      //     // 转换完成，创建一个a标签用于下载
      //     console.log("reader.onload", e);
      //     const a = document.createElement("a");
      //     console.log(decodeURIComponent(fileName));
      //     a.download = decodeURIComponent(fileName) + ".xlsx"; // 构建 下载的文件名称以及下载的文件格式（可通过传值输入）
      //     if (typeof e.target.result === "string") {
      //       a.href = e.target.result;
      //     }
      //     a.click();
      //     a.remove();
      //     window.URL.revokeObjectURL(url); // 释放内存
      //   };
      // });

      var ws = XLSX.utils.json_to_sheet([], {
        header: ["故障代码", "故障描述"],
      });
      this.selectedRows.forEach((elem) => {
        XLSX.utils.sheet_add_json(
          ws,
          [{ 故障代码: "" + elem.errCode, 故障描述: "" + elem.errContent }],
          {
            header: ["故障代码", "故障描述"],
            skipHeader: true,
            origin: -1,
          }
        );
      });
      console.log("exportTest", ws);
      // var csv = XLSX.utils.sheet_to_csv(ws);
      // console.log("exportTest csv", csv);
      this.openDownloadDialog(this.sheet2blob(ws), "导出.xlsx");
    },
    exportTest() {
      //
      console.log("exportTest ", this.selectedRows);
    },
    handleActoinClick(e) {
      console.log("click", e);
      if (e.key === "1") {
        this.saveAll();
      }
      if (e.key === "2") {
        this.deleteConfim();
      }
      if (e.key === "3") {
        this.selectedRowKeys = [];
        this.onSelectChange(this.selectedRowKeys);
      }
    },
    async saveAll() {
      console.log("saveAll", this.selectedRowKeys);
      let key = "saveAll" + Date.now();
      let error = 0;
      if (this.selectedRowKeys.length > 0) {
        this.$message.loading({ content: "正在保存...", key, duration: 0 });
        this.actionLoading = true;
        this.tableLoading = true;
        // this.selectedRowKeys.forEach(elem => {
        //   this.saveKey(elem);
        // })
        // let pr = this.selectedRowKeys.map(key => { return await this.saveKey(key)});
        // let res = await Promise.all()
        let res = [this.selectedRowKeys.length];
        for (let i = 0; i < this.selectedRowKeys.length; i++) {
          try {
            res[i] = await this.saveKey(this.selectedRowKeys[i]);
          } catch (e) {
            res[i] = e;
            error++;
          }
        }
        console.log("saveAll res", res);
        if (error === 0) {
          this.$message.success({ content: "保存成功完成!", key, duration: 3 });
        } else {
          this.$message.warn({
            content: `有${error}条数据保存失败，请检查!`,
            key,
            duration: 4,
          });
        }
        this.actionLoading = false;
        this.tableLoading = false;
      } else {
        this.$message.info({ content: "请勾选需要保存的数据!", duration: 3 });
      }
    },
    deleteConfim() {
      console.log("deleteConfim");
      if (this.selectedRowKeys.length > 0) {
        this.confirmTitle = "提示";
        this.confirmText = `确定删除${this.selectedRowKeys.length}个项目吗？`;
        this.confirmDialge = () => {
          this.confirmDialge = undefined;
          this.delectSelected();
        };
        this.addNewForm = true;
      } else {
        this.$message.info({ content: "请勾选需要删除的数据!", duration: 3 });
      }
    },
    async delectSelected() {
      console.log("delectSelected", this.selectedRowKeys);
      let key = "delectSelected" + Date.now();
      let error = 0;
      if (this.selectedRowKeys.length > 0) {
        this.$message.loading({ content: "正在删除...", key, duration: 0 });
        this.actionLoading = true;
        this.tableLoading = true;
        let als = [...this.selectedRowKeys];
        // Promise.allSettled(al).then(res => { })
        let res = [als.length];
        for (let i = 0; i < als.length; i++) {
          try {
            res[i] = await this.remove(als[i]);
          } catch (e) {
            res[i] = e;
            error++;
          }
        }
        console.log("removeAll res", res);
        if (error === 0) {
          this.$message.success({
            content: "删除操作成功完成!",
            key,
            duration: 3,
          });
        } else {
          this.$message.warn({
            content: `有${error}条数据操作失败，请检查!`,
            key,
            duration: 4,
          });
        }
        this.actionLoading = false;
        this.tableLoading = false;
      } else {
        this.$message.info({ content: "请勾选需要删除的数据!", duration: 3 });
      }
    },
    sheet2blob(sheet, sheetName) {
      sheetName = sheetName || "sheet1";
      var workbook = {
        SheetNames: [sheetName],
        Sheets: {},
      };
      workbook.Sheets[sheetName] = sheet;
      // 生成excel的配置项
      var wopts = {
        bookType: "xlsx", // 要生成的文件类型
        bookSST: false, // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        type: "binary",
      };
      var wbout = XLSX.write(workbook, wopts);
      var blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
      // 字符串转ArrayBuffer
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      return blob;
    },
    openDownloadDialog(url, saveName) {
      if (typeof url == "object" && url instanceof Blob) {
        url = URL.createObjectURL(url); // 创建blob地址
      }
      var aLink = document.createElement("a");
      aLink.href = url;
      aLink.download = saveName || ""; // HTML5新增的属性，指定保存文件名，可以不要后缀，注意，file:///模式下不会生效
      var event = new MouseEvent("click");
      aLink.dispatchEvent(event);
    },
  },
};
</script>

<style lang="less" scoped>
.faultCodeList {
  a {
    user-select: none;
    &.warn_color {
      color: #a52a2a;
    }
  }
  a:hover {
    filter: brightness(1.5);
    &.warn_color {
      color: #a52a2a;
    }
  }
  .search {
    margin-bottom: 54px;
  }
  .highlight {
    background-color: rgb(255, 192, 105);
    padding: 0px;
  }
  .fold {
    width: calc(100% - 216px);
    display: inline-block;
  }
  .operator {
    margin-bottom: 18px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    &_select {
      margin-left: auto;
      margin-right: 10px;
    }
    &_date {
      width: 220px;
    }
  }
  :deep(.ant-table-body) {
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 3px;
      height: 1px;
    }
    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 3px;
      background: @primary-color;
    }
    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
      border-radius: 3px;
      background: @primary-3;
    }
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
}
</style>
