var render = function render(){var _vm=this,_c=_vm._self._c;return _c('popup-form',{attrs:{"visible":_vm.visible,"title":_vm.title,"maskClosable":false},on:{"cancel":() => { _vm.$emit('cancel') },"ok":() => { _vm.$emit('create') }}},[_c('a-form',{attrs:{"layout":"vertical","form":_vm.form},on:{"submit":_vm.submitForm}},[_c('a-form-item',{attrs:{"label":"设备名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'newmodel.deviceModelName',
          {
            initialValue: _vm.formdata.deviceModelName,
            rules: [{ required: true, message: '请输入设备名称' }],
          }
        ]),expression:"[\n          'newmodel.deviceModelName',\n          {\n            initialValue: formdata.deviceModelName,\n            rules: [{ required: true, message: '请输入设备名称' }],\n          }\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"设备型号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'newmodel.deviceModelType',
          {
            initialValue: _vm.formdata.deviceModelType,
            rules: [{ required: true, message: '请输入设备型号' }],
          }
        ]),expression:"[\n          'newmodel.deviceModelType',\n          {\n            initialValue: formdata.deviceModelType,\n            rules: [{ required: true, message: '请输入设备型号' }],\n          }\n        ]"}]})],1),_c('a-form-item',{attrs:{"label":"备注"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'newmodel.remarks',
          {
            initialValue: _vm.formdata.remarks,
          }
        ]),expression:"[\n          'newmodel.remarks',\n          {\n            initialValue: formdata.remarks,\n          }\n        ]"}],attrs:{"type":"textarea"}})],1),_c('a-form-item',{attrs:{"label":"设备处理功能"}},[_c('a-checkbox-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'newmodel.clwgn', 
          { initialValue: _vm.formdata.clwgn, 
            rules: [{ required: true, message: '请选择一种' }], 
          }
        ]),expression:"[\n          'newmodel.clwgn', \n          { initialValue: formdata.clwgn, \n            rules: [{ required: true, message: '请选择一种' }], \n          }\n        ]"}],staticStyle:{"width":"100%"}},[_c('a-checkbox',{attrs:{"value":"yjgf"}},[_vm._v(" 有机固废 ")]),_c('a-checkbox',{attrs:{"value":"yllj"}},[_vm._v(" 园林垃圾 ")])],1)],1),_c('a-button',{staticStyle:{"display":"none"},attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" Submit ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }