<template>
  <div class="model_page">
    <sub-form
      ref="newForm"
      :visible="showForm"
      :title="sftitle"
      :formdata="sfdata"
      @cancel="hideForm"
      @create="addNewForm"
    />
    <fault-code-form 
      ref="faultCodeForm"
      v-if="showFaultCodeFormV"
      :visible="showFaultCodeForm"
      :id="deviceModelId"
      @cancel="CloseFaultCodeForm"
    />
    <div class="table_ctrl">
      <a-space class="operator">
        <b style="font-size: 24px;">型号管理</b>
        <span>（ 共 {{ total }} 条数据 ）</span>
        <a-button @click="addNew" type="primary"
          ><a-icon type="plus-circle" theme="filled" />新增型号</a-button
        >
      </a-space>
      <a-space class="operator2">
        <!-- <a-button type="primary" icon="reload" @click="update" /> -->
        <a-input-search
          class="operator2_search"
          v-model="keyword"
          placeholder="输入设备ID、设备型号、单位、联系人、电话"
          allow-clear
          @search="onSearch"
        >
          <a-button type="primary" icon="search" slot="enterButton">
            搜索
          </a-button>
        </a-input-search>
      </a-space>
    </div>
    <standard-table
      :columns="columns"
      :dataSource="dataSource"
      :pagination="{
        total: total,
        pageSize: pagination.pageSize,
      }"
      @clear="onClear"
      @change="onChange"
      @selectedRowChange="onSelectChange"
    >
      <div slot="description" slot-scope="{ text }">
        {{ text }}
      </div>
      <div slot="action" slot-scope="{ record }">
        <!-- <a icon="search" @click="viewRecord(record)" style="margin-right: 8px">
          <a-icon type="zoom-in" />
        </a>
        <a-dropdown placement="bottomRight">
          <a class="ant-dropdown-link" @click="(e) => e.preventDefault()">
            <a-icon type="bars" />
          </a>
          <a-menu slot="overlay">
            <a-menu-item key="0">
              <a @click="editForm(record)" style="margin-right: 8px">
                <a-icon type="form" /> 编辑
              </a>
            </a-menu-item>
            <a-menu-item key="1">
              <a  @click="deleteRecord(record)"  style="margin-right: 8px" >
                <a-icon type="delete" /> 删除
              </a>
            </a-menu-item>
          </a-menu>
        </a-dropdown> -->
        <a style="margin-right: 8px" @click="editFaultCodeForm(record)">
          <a-icon type="control" /> 故障编码
        </a>
        <a style="margin-right: 8px" @click="editForm(record)">
          <a-icon type="edit" />编辑
        </a>
        <a style="margin-right: 8px" @click="deleteRecord(record)">
          <a-icon type="delete" />删除
        </a>
        <!-- <a-popconfirm
          title="Sure to del?"
          @confirm="() => deleteRecord(record.key)"
        >
          <a> <a-icon type="delete" />删除 </a>
        </a-popconfirm> -->
      </div>
      <template slot="statusTitle">
        <a-icon @click.native="onStatusTitleClick" type="info-circle" />
      </template>
    </standard-table>
    <popup-form
      :visible="visibleConfirm"
      :width="460"
      :maskClosable="false"
      title="确定删除？"
      oktext="确定"
      :destroyOnClose="true"
      @cancel="
        () => {
          this.visibleConfirm = false;
        }
      "
      @ok="
        () => {
          this.visibleConfirm = false;
          this.confirmDelete();
        }
      "
    >
      确定删除型号 <span style="color:#1b79d7;">{{ visibleConfirmSn }}</span> ？
    </popup-form>
  </div>
</template>

<script>
import StandardTable from "@/components/table/StandardTable";
import PopupForm from "@/pages/components/PopupForm";
import subForm from "./subForm";
import FaultCodeForm from "./FaultCodeForm.vue"
import { logout } from '@/services/user'
const columns = [
  {
    title: "设备名称",
    dataIndex: "deviceModelName",
    ellipsis: true,
  },
  {
    title: "设备型号",
    dataIndex: "deviceModelType",
    ellipsis: true,
  },
  {
    title: "备注",
    dataIndex: "remarks",
    scopedSlots: { customRender: "description" },
    ellipsis: true,
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" },
    width: "240px",
  },
];

// eslint-disable-next-line no-unused-vars
import { dataSource as ds } from "@/services";
export default {
  name: "UserList",
  components: { StandardTable, subForm, PopupForm, FaultCodeForm },
  data() {
    return {
      advanced: true,
      showForm: false,
      columns: columns,
      dataSource: [],
      selectedRows: [],
      total: 0,
      pagination: {
        current: 1,
        pageSize: 13,
      },
      sftitle: undefined,
      sfdata: undefined,
      editmode: false,
      editmodekey: "",
      keyword: "",
      visibleConfirm: false,
      visibleConfirmSn: "",
      visibleConfirmId: "",
      showFaultCodeForm: false,
      showFaultCodeFormV: false,
      sfctitle: "故障编码",
      deviceModelId: null,
    };
  },
  authorize: {
    // deleteRecord: 'delete'
  },
  mounted() {
    this.update();
  },
  methods: {
    update() {
      // this.$message.info("update");
      ds.deviceModelList({
        pageIndex: this.pagination.current,
        pageSize: this.pagination.pageSize,
        keyword: this.keyword,
      })
        .then((res) => {
          console.log("deviceModelList", res);
          if (res.data.meta.success) {
            console.log("dataSource", res.data.data.list);
            this.dataSource = res.data.data.list.list.map((item) => {
              return {
                ...item,
                key: item.deviceModelId,
              };
            });
            this.total = res.data.data.list.total;
          } else {
            if(res.data.meta.code === 1006) {
              this.$message.error('获取数据出错：登录已过期，请重新登录');
              logout();
              this.$router.push('/login')
            } else {
              this.$message.error('获取数据出错：' + res.data.meta.msg);
            }
          }
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },
    onSearch() {
      // this.$message.info("onSearch");
      this.update();
    },
    deleteRecord(key) {
      console.log("deleteRecord", key);
      this.visibleConfirm = true;
      // this.dataSource = this.dataSource.filter(item => item.key !== key)
      // this.selectedRows = this.selectedRows.filter(item => item.key !== key)
      this.visibleConfirmSn = key.deviceModelName;
      this.visibleConfirmId = key.deviceModelId;
    },
    confirmDelete() {
      ds.delDeviceModel(this.visibleConfirmId)
        .then((res) => {
          console.log("delDeviceModel: ", res);
          if (res.data.meta.success) {
            this.$message.success("删除成功");
            this.update();
          } else {
            this.$message.error(res.data.meta.msg);
          }
        })
        .catch((err) => {
          console.log("delDeviceModelErr: ", err);
        });
    },
    toggleAdvanced() {
      this.advanced = !this.advanced;
    },
    remove() {
      this.dataSource = this.dataSource.filter(
        (item) =>
          this.selectedRows.findIndex((row) => row.key === item.key) === -1
      );
      this.selectedRows = [];
    },
    onClear() {
      this.$message.info("您清空了勾选的所有行");
    },
    onStatusTitleClick() {
      this.$message.info("你点击了状态栏表头");
    },
    onChange(e) {
      // this.$message.info('表格状态改变了')
      console.log("onChange", e);
      const { current } = e;
      this.pagination.current = current;
      this.update();
    },
    onSelectChange() {
      this.$message.info("选中行改变了");
    },
    addNew() {
      this.sftitle = undefined;
      this.sfdata = undefined;
      this.editmode = false;
      this.showForm = true;
    },
    hideForm() {
      let form = this.$refs.newForm.form;
      form.resetFields();
      this.showForm = false;
    },
    addNewForm() {
      let form = this.$refs.newForm.form;
      console.log("newform");
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        values.newmodel.remarks = values.newmodel.remarks ?? "";
        if(values.newmodel.clwgn?.includes('yjgf')) {
          values.newmodel.yjgf = 1;
        } else {
          values.newmodel.yjgf = 0;
        }
        if(values.newmodel.clwgn?.includes('yllj')) {
          values.newmodel.yllj = 1;
        } else {
          values.newmodel.yllj = 0;
        }
        if (this.editmode) {
          values.newmodel.deviceModelId = this.editmodekey;
          console.log("Received values of form: ", values);
          ds.editDeviceModel(values.newmodel)
            .then((res) => {
              console.log("editDeviceModel: ", res);
              if (res.data.meta.success) {
                this.$message.success("修改成功");
                form.resetFields();
                this.showForm = false;
                this.update();
              } else {
                this.$message.error(res.data.meta.msg);
              }
            })
            .catch((err) => {
              console.log("deviceModelErr: ", err);
            });
        } else {
          console.log("Received values of form: ", values);
          ds.deviceModel(values.newmodel)
            .then((res) => {
              console.log("deviceModel: ", res);
              if (res.data.meta.success) {
                this.$message.success("添加成功");
                form.resetFields();
                this.showForm = false;
                this.update();
              } else {
                this.$message.error(res.data.meta.msg);
              }
            })
            .catch((err) => {
              console.log("deviceModelErr: ", err);
            });
        }
      });
    },
    editForm(record) {
      console.log("editForm", record);
      this.sftitle = "编辑型号";
      this.sfdata = {
        deviceModelName: record.deviceModelName,
        deviceModelType: record.deviceModelType,
        remarks: record.remarks,
        clwgn: [],
      };
      if(record.yjgf === 1) {
        this.sfdata.clwgn.push('yjgf')
      }
      if(record.yllj === 1) {
        this.sfdata.clwgn.push('yllj')
      }
      this.editmode = true;
      this.editmodekey = record.key;
      this.showForm = true;
    },
    editFaultCodeForm(record) {
      console.log("editFaultCodeForm", record);
      this.deviceModelId = record.deviceModelId;
      this.showFaultCodeFormV = true;
      this.showFaultCodeForm = true;
    },
    CloseFaultCodeForm() {
      this.showFaultCodeForm = false;
      setTimeout(()=>{
        this.showFaultCodeFormV = false;
      }, 300)
    },
    handleMenuClick(e) {
      if (e.key === "delete") {
        this.remove();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.model_page {
  padding: 6px;
  margin-top: 7px;
  // margin-bottom: 20px;
  background: rgba(0, 20, 74, 0.8);
  border: 18px solid #027cc3;
  border-image:url(../../../assets/img/card-b.png) 18 round;
  box-shadow: 0px 0px 25px 0px rgba(0, 138, 255, 0.4);
  color: #00effc;
  :deep(.ant-table) {
    color: #fff;
  }
  :deep(.ant-table-thead) {
    > tr {
      > th {
        background-color: #02215e;
        color: #00f9ff;
        border-bottom: 0px;
      }
    }
  }
  :deep(.ant-empty-normal) {
    // min-height: 190px;
    background-color: #fff0;
    color: #00f8fe;
  }
  :deep(.ant-table-tbody) {
    > tr {
      > td {
        border-bottom: 1px solid #fff2;
      }
      &:hover {
        > td {
          background-color: #fff2;
        }
      }
    }
  }
  :deep(.ant-table-placeholder) {
    background-color: #fff0;
    color: #00f9ff;
  }

  :deep(.ant-table-pagination) {
    .ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link {
      background-color: #30a7f8;
      border: 1px solid #027cc3;
    }
    .ant-pagination-item {
      background-color: #30a7f8;
      border: 1px solid #027cc3;
    }
    .ant-pagination-item a {
      color: #2226;
    }
    .ant-pagination-item a:hover {
      color: #2229;
    }
    .ant-pagination-item-active a {
      color: #000e;
    }
  }

  .table_ctrl {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .operator2_search {
      width: 300px;
      :deep(.ant-input) {
        background-color: #021548;
        border-color: #0f4583;
        color: #fff;
      }
      :deep(.ant-input-clear-icon) {
        color: #fff6;
        &:hover {
          color: #fff;
        }
      }
    }
    .operator {
      display: flex;
      align-items: center;
    }
  }
  .search {
    margin-bottom: 54px;
  }
  .fold {
    width: calc(100% - 216px);
    display: inline-block;
  }
  @media screen and (max-width: 900px) {
    .fold {
      width: 100%;
    }
  }
  // :deep(.ant-table{
  //   min-height: 740px;
  // }
  // :deep(.ant-empty-normal{
  //   min-height: 740px;
  // }
}
</style>
